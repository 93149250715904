import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "hidden", "results"]
  static values = {}

  connect() {
    this.inputTarget.addEventListener("input", this.onInputChange.bind(this))
    this.inputTarget.addEventListener("keydown", this.onKeydown.bind(this))
    this.resultsTarget.style.top = '70px'
  }

  onInputChange() {
    this.filterList(this.inputTarget.value)
  }

  onLostFocus() {
    this.resultsTarget.style.display = 'none'
  }

  onKeydown(event) {
    if (event.keyCode == 27) {
      this.resultsTarget.style.display = 'none'
    }
  }

  filterList(filter) {
    const search = new RegExp(filter, 'gi')
    this.resultsTarget.querySelectorAll('li.option').forEach(li => {
      if( li.innerText.match(search) ) {
        li.style.display = 'block'
      } else {
        li.style.display = 'none'
      }
    })

    if (filter === '') {
      this.resultsTarget.style.display = 'none'
    } else {
      this.resultsTarget.style.display = 'block'
    }
  }
}
