import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  time(event) {
    if (this.hasInputTarget) {
      event.stopPropagation()
      event.preventDefault()
      const currentValues = this.inputTarget.value.split('T')
      const newTime = event.currentTarget.dataset.time
      this.inputTarget.value = [currentValues[0], newTime].join('T')
    }
  }
}
